<template>
  <loading :active="isLoadingA" :is-full-page="true" :loader="'bars'" />
  <LoadingBar v-if="isLoading"></LoadingBar>
  <div class="flex flex-col h-full">
    <page-header>
      <page-title
        :title="
          billingStatus == 0 ? `Billing` : `Your subscription is ${status}`
        "
      >
        <span
          @click="createSubscription()"
          class="px-5 py-2 ml-2 cursor-pointer"
          v-if="billingStatus == 3"
        >
          Create Subscription
        </span>
        <span class="px-5 py-2 ml-2" v-else-if="billingStatus > 0">
          Renews on
          {{ renewDate }}
        </span>
        <span
          v-else
          @click="openAddCardModal()"
          class="px-5 py-2 ml-2 cursor-pointer"
        >
          Add Payment Method
        </span>
      </page-title>
    </page-header>
    <div v-if="showAddCardPopup">
      <AddCard @exit="closeAddCard"></AddCard>
    </div>

    <div v-if="showUpdateBilling">
      <UpdateBilling
        @exit="showUpdateBilling = false"
        @cardAdded="getCardinfo()"
      ></UpdateBilling>
    </div>
    <div class="vendor-container" v-if="billingStatus == 1">
      <div class="flex flex-row justify-between mt-4">
        <div
          class="
            flex flex-col
            bg-white
            h-32
            rounded-md
            place-content-end
            py-5
            px-7
            text-xs
          "
        >
          <span class="mb-4">XXXX XXXX XXXX XXXX {{ last4 }}</span>
          <div class="flex flex-row justify-between">
            <div class="text-left">
              <p>EXP</p>
              <span>{{ exp_month }}/{{ exp_year }}</span>
            </div>
            <div></div>
          </div>
        </div>
        <div>
          <div class="flex flex-col items-end py-5">
            <div class="w-full">
              <button
                @click="UpdateBilling()"
                class="px-2 py-2 w-full text-white rounded-md bg-qgo-primary-bg"
              >
                Update Billing Information
              </button>
            </div>
            <div class="w-full mt-4">
              <button
                class="px-5 py-2 w-full text-white bg-red-500 rounded-md"
                @click="cancelSubscription"
              >
                Pause/Cancel Subscription
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="vendor-content">
        <div
          class="
            inline-block
            min-w-full
            overflow-hidden
            align-middle
            border-b border-gray-200
            sm:rounded-lg
          "
        >
          <div class="flex flex-row items-center py-2 mt-5 bg-white rounded-md">
            <div class="flex w-1/2 ml-5">
              <span class="ml-5 text-xl font-bold text-left">Invoices</span>
            </div>
            <div class="flex flex-row justify-end w-1/2 mr-5">
              <button
                class="ml-5 text-sm text-left border-b"
                @click="downloadStatement"
              >
                Download Statement
              </button>
            </div>
          </div>
          <div>
            <div
              class="
                py-2
                pr-10
                -my-2
                overflow-x-auto
                sm:-mx-6 sm:px-6
                lg:-mx-8 lg:px-8
              "
            >
              <div
                class="
                  inline-block
                  min-w-full
                  px-8
                  pt-3
                  overflow-hidden
                  align-middle
                  bg-white
                  rounded-bl-lg rounded-br-lg
                  shadow shadow-dashboard
                "
              >
                <table class="min-w-full">
                  <thead>
                    <tr>
                      <th
                        class="
                          px-6
                          py-3
                          leading-4
                          text-left
                          border-b-2 border-gray-300
                          text-qgo-primary-bg
                        "
                      >
                        Select
                      </th>
                      <th
                        class="
                          px-6
                          py-3
                          text-sm
                          leading-4
                          tracking-wider
                          text-left
                          border-b-2 border-gray-300
                          text-qgo-primary-bg
                        "
                      >
                        Date
                      </th>
                      <th
                        class="
                          px-6
                          py-3
                          text-sm
                          leading-4
                          tracking-wider
                          text-left
                          border-b-2 border-gray-300
                          text-qgo-primary-bg
                        "
                      >
                        Status
                      </th>
                      <th
                        class="
                          px-6
                          py-3
                          text-sm
                          leading-4
                          tracking-wider
                          text-left
                          border-b-2 border-gray-300
                          text-qgo-primary-bg
                        "
                      >
                        Invoice#
                      </th>
                      <th
                        class="
                          px-6
                          py-3
                          text-sm
                          leading-4
                          tracking-wider
                          text-left
                          border-b-2 border-gray-300
                          text-qgo-primary-bg
                        "
                      >
                        Amount
                      </th>
                      <th
                        class="
                          px-6
                          py-3
                          text-sm
                          leading-4
                          tracking-wider
                          text-left
                          border-b-2 border-gray-300
                          text-qgo-primary-bg
                        "
                      >
                        Type
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="billing in invoicesList"
                    :key="billing.id"
                    class="bg-white"
                  >
                    <tr>
                      <td
                        class="
                          px-6
                          py-4
                          whitespace-no-wrap
                          border-b border-gray-500
                        "
                      >
                        <div class="flex items-center">
                          <input
                            type="checkbox"
                            class="focus:ring-0 focus:border-none"
                            v-model="checkedItems"
                            :value="billing.id"
                          />
                        </div>
                      </td>
                      <td
                        class="
                          px-6
                          py-4
                          whitespace-no-wrap
                          border-b border-gray-500
                        "
                      >
                        <div class="text-sm leading-5 text-left text-blue-900">
                          {{ date(billing.created_at) }}
                        </div>
                      </td>
                      <td
                        class="
                          px-6
                          py-4
                          text-sm
                          leading-5
                          text-left text-blue-900
                          whitespace-no-wrap
                          border-b border-gray-500
                        "
                      >
                        <span
                          class="
                            relative
                            inline-block
                            px-3
                            py-1
                            font-semibold
                            leading-tight
                            text-green-900
                          "
                        >
                          <span
                            aria-hidden
                            :class="[
                              billing.status === 'paid' ||
                              billing.status === 'succeeded'
                                ? 'absolute inset-0 bg-green-200 opacity-50 rounded-full'
                                : 'absolute inset-0 bg-red-200 opacity-50 rounded-full',
                            ]"
                          ></span>
                          <span class="relative text-xs">
                            {{ billing.status }}
                          </span>
                        </span>
                      </td>
                      <td
                        class="
                          px-6
                          py-4
                          text-sm
                          leading-5
                          text-left text-blue-900
                          whitespace-no-wrap
                          border-b border-gray-500
                        "
                        v-if="billing.transaction_type === 'subscription'"
                      >
                        {{ billing.invoice_number }}
                      </td>
                      <td
                        class="
                          px-6
                          py-4
                          text-sm
                          leading-5
                          text-left text-blue-900
                          whitespace-no-wrap
                          border-b border-gray-500
                        "
                        v-else-if="billing.transaction_type === 'top-up'"
                      >
                        <a :href="`${billing.receipt_url}`" target="_blank"
                          >Open Receipt</a
                        >
                      </td>
                      <td
                        class="
                          px-6
                          py-4
                          text-sm
                          leading-5
                          text-left text-blue-900
                          whitespace-no-wrap
                          border-b border-gray-500
                        "
                      >
                        {{ billing.amount }}
                      </td>
                      <td
                        class="
                          px-6
                          py-4
                          text-sm
                          leading-5
                          text-left text-blue-900
                          whitespace-no-wrap
                          border-b border-gray-500
                        "
                      >
                        {{ billing.transaction_type }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="flex flex-row items-start py-8 bg-white">
            <div class="flex w-1/2 ml-5">
              <span class="ml-5 text-left"
                >Bulk Actions for Select ( {{ checkedItems.length }} )</span
              >
            </div>
            <div class="flex flex-row justify-end w-1/2 mr-5">
              <!-- <button
                class="px-2 py-2 bg-gray-200 rounded-md text-qgo-primary-bg"
                @click="deselectItems"
              >
                Deselect All
              </button> -->
              <!-- <button
                class="
                  px-2
                  py-2
                  ml-2
                  bg-gray-200
                  rounded-md
                  text-qgo-primary-bg
                "
                @click="downloadInvoice"
              >
                Download PDF Invoice
              </button> -->
              <!-- <button
                class="px-2 py-2 ml-2 text-white rounded-md bg-qgo-primary-bg"
              >
                Pay Invoices
              </button> -->
            </div>
          </div>
          <!-- <div class="flex flex-row items-start py-5">
            <div class="flex w-1/2 ml-5">
              <button
                class="px-2 py-2 ml-2 text-white rounded-md bg-qgo-primary-bg"
              >
                Update Billing Information
              </button>
            </div>
            <div class="flex flex-row justify-end w-1/2 mr-5">
              <button
                class="px-5 py-2 ml-2 text-white bg-red-500 rounded-md"
                @click="cancelSubscription"
              >
                Cancel Subscription
              </button>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="vendor-container msg-outer" v-else>
      <div class="vendor-inner-custom">
        <img src="@/assets/img/card.png" alt="" />
        <h3 v-if="billingStatus == 0">WELCOME TO YOUR ACCOUNT</h3>
        <p v-if="billingStatus == 0">
          To begin using your subscription,<br />Please add a payment method on
          the Billing screen.
        </p>
        <h3 v-if="billingStatus == 2">
          YOUR SUBSCRIPTION IS<br />CURRENTLY INACTIVE
        </h3>
        <p v-if="billingStatus == 2">
          Please add a payment method to begin using<br />your Q&GO Vendors
          account.
        </p>
        <h3 v-if="billingStatus == 3">
          YOUR SUBSCRIPTION IS<br />CURRENTLY PAUSED
        </h3>
        <p v-if="billingStatus == 3">
          Please unpause your subscription on the Billing screen<br />
          in order to resume using your service.
        </p>
        <!-- <div class="add-icon">
        <div>
          <span>+</span>
        </div>
      </div> -->
      </div>
    </div>
    <page-footer>
      <footer-nav></footer-nav>
    </page-footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PageHeader from "@/components/pages/Header.vue";
import PageTitle from "@/views/Vendor/PageTitle.vue";
import PageFooter from "@/components/pages/Footer.vue";
import FooterNav from "@/views/Vendor/FooterNav.vue";
import AddCard from "@/views/Vendor/Modal/BillingAddCard.vue";
import UpdateBilling from "@/views/Vendor/Modal/UpdateBilling.vue";
import LoadingBar from "@/components/loader/Loader.vue";
import moment from "moment";
import Loading from "vue-loading-overlay";
import axios from "axios";
//import TableView from "@/views/Table/Table.vue";

export default defineComponent({
  setup() {
    return {};
  },
  components: {
    PageHeader,
    PageTitle,
    PageFooter,
    FooterNav,
    LoadingBar,
    Loading,
    AddCard,
    UpdateBilling,
  },
  data() {
    return {
      checkedItems: [],
      channel: null,
      userId: undefined,
      isLoadingA: false,
      invoicesList: [],
      billingStatus: 0,
      status: "",
      showAddCardPopup: false,
      renewDate: "",
      last4: "",
      exp_month: "",
      exp_year: "",
      showUpdateBilling: false,
    };
  },
  methods: {
    closeAddCard() {
      this.showAddCardPopup = false;
      this.checkBillingStatus();
    },
    UpdateBilling() {
      this.showUpdateBilling = true;
    },
    getInvoicesList() {
      this.isLoadingA = true;
      this.$http
        .get("invoice/list")
        .then((response) => {
          this.isLoadingA = false;
          if (response && response.status) {
            this.invoicesList = response.data.data;
          }
        })
        .catch((error) => {
          this.isLoadingA = false;
          this.$swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 5000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
        });
    },
    deselectItems() {
      this.checkedItems = [];
    },
    date: function (date) {
      return moment(date).format("MM/D/YYYY");
    },
    cancelSubscription() {
      let user = JSON.parse(localStorage.getItem("userData"));
      this.$swal
        .fire({
          text: "Are you sure you want to cancel your subscription?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes!",
          cancelButtonText: "No!",
          reverseButtons: true,
          customClass: {
            confirmButton: "swal-confirm-button",
            cancelButton: "swal-cancel-button",
          },
          buttonsStyling: false,
          allowOutsideClick: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            let body = {
              user_id: user.id,
            };
            this.$http
              .post("subscription/cancel")
              .then((response) => {
                this.$swal.fire({
                  icon: "success",
                  title: "Subscription paused successfully",
                  timer: 5000,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                });
                this.billingStatus = 3;
                this.status = "Cancelled";
              })
              .catch((error) => {
                this.isLoadingA = false;
                this.$swal.fire({
                  icon: "error",
                  title: error.response.data.message,
                  timer: 5000,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                });
              });
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          }
        });
    },
    downloadStatement() {
      if (!this.invoicesList.length) {
        this.$swal.fire({
          icon: "error",
          title: "No invoices to export",
          timer: 3000,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
        });
        return;
      }
      this.$store.commit("billing/setLoading", true);
      this.$http
        .get("invoice/download")
        .then((response) => {
          // var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          // var fileLink = document.createElement("a");

          // fileLink.href = fileURL;
          // fileLink.setAttribute("download", "statement.csv");
          // document.body.appendChild(fileLink);

          // fileLink.click();
          var fileLink = document.createElement("a");
          fileLink.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(response.data);
          let filename = `Q&GO-Statement${
            new Date().toISOString().split("T")[0]
          }.csv`;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
          this.$store.commit("billing/setLoading", false);
        })
        .catch((error) => {
          let errorMessage = error.message;
          console.error("There was an error!", error);
          console.log(errorMessage);
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong while trying to download Statement!",
            timer: 5000,
          });
          this.$store.commit("billing/setLoading", false);
        });
    },
    checkBillingStatus() {
      this.$http
        .get("subscription_items")
        .then((response) => {
          if (response && response.data.status) {
            console.log(response.data);
            this.billingStatus = response.data.subscription_code;
            this.status =
              this.billingStatus == 1
                ? "active"
                : this.billingStatus == 2
                ? "inactive"
                : "paused";
            //0: no card added, 1: Active, 2: Inactive, 3: Paused
            // if billing status is active then call apis
            if (this.billingStatus == 1) {
              this.getInvoicesList();
            }
            if (this.billingStatus > 0) {
              this.getCardinfo();
              if (response.data.data && response.data.data.current_period_end) {
                let time =
                  parseInt(response.data.data.current_period_end) * 1000;
                let d = new Date(time);
                this.renewDate =
                  d.getDate() +
                  "/" +
                  (d.getMonth() + 1) +
                  "/" +
                  d.getFullYear();
              }
            }
          } else {
            this.billingStatus = 0;
          }
        })
        .catch((error) => {
          this.isLoadingA = false;
        });
    },
    getCardinfo() {
      this.$http
        .get("card/show")
        .then((response) => {
          if (response.data.message == "No any default card exist") {
            this.last4 = "";
          } else if (response.data.card && response.data.card.last4) {
            this.last4 = response.data.card.last4;
            this.exp_month = response.data.card.exp_month.toString();
            if (this.exp_month.length == 1) {
              this.exp_month = "0" + this.exp_month;
            }
            this.exp_year = response.data.card.exp_year.toString().slice(-2);
          }
        })
        .catch((error) => {
          this.isLoadingA = false;
          this.$swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 5000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
        });
    },
    openAddCardModal() {
      this.showAddCardPopup = true;
    },
    createSubscription() {
      this.isLoadingA = true;
      this.$http
        .post("subscription/create", {})
        .then((response) => {
          console.log(response.data);   
          if (response.data && response.data.status) {
            this.$swal.fire({
              icon: "success",
              title: response.data.message,
              timer: 5000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
            console.log(response.data);
            if (
              response.data.data.latest_invoice &&
              response.data.data.latest_invoice.payment_intent &&
              response.data.data.latest_invoice.payment_intent.next_action
            ) {
              if (
                response.data.data.latest_invoice.payment_intent.next_action
                  .use_stripe_sdk &&
                response.data.data.latest_invoice.payment_intent.next_action
                  .use_stripe_sdk.stripe_js
              ) {
                window.open(response.data.data.latest_invoice.payment_intent.next_action
                  .use_stripe_sdk.stripe_js);
              }
            }

            setTimeout(() => {
              this.checkBillingStatus();
              this.isLoadingA = false;
            }, 4000);

            this.$emit("exit");
          } else {
            this.$swal.fire({
              icon: "error",
              title: response.data.message,
              timer: 5000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          }
        })
        .catch((error) => {
          this.isLoadingA = false;
          console.error("There was an error!", error);
          this.$swal.fire({
            icon: "error",
            text: error.response.data.message,
            timer: 5000,
          });
        });
    },
    downloadInvoice() {
      let id = this.checkedItems[0];
      this.$store.commit("billing/setLoading", true);
      if (id) {
        this.$http
          .get("invoice/pdfstatement?invoice_id=" + id)
          .then(async (response) => {
            window.open(response.data.url, "_blank");
            this.$store.commit("billing/setLoading", false);
            // const responses = await axios.get(response.data.url, { responseType: "blob" });
            // const blob = new Blob([responses.data], { type: "application/pdf" });
            // const link = document.createElement("a");
            // link.href = URL.createObjectURL(responses.blob());
            // link.download = 'helloo.pdf';
            // link.click();
            // URL.revokeObjectURL(link.href);
          })
          .catch((error) => {
            let errorMessage = error.message;
            console.error("There was an error!", error);
            console.log(errorMessage);
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong while trying to download PDF Invoice!",
              timer: 5000,
            });
            this.$store.commit("billing/setLoading", false);
          });
      } else {
        this.$swal.fire({
          icon: "info",
          text: "Please Select which Invoice to download.",
          timer: 5000,
        });
        this.$store.commit("billing/setLoading", false);
      }
    },
  },
  watch: {
    checkedItems() {
      console.log(this.checkedItems);
    },
  },
  computed: {
    billingData() {
      return this.$store.getters["billing/inputData"];
    },
    // status() {
    //   return this.$store.getters["billing/status"];
    // },
    renewalDate() {
      return this.$store.getters["billing/renewalDate"];
    },
    isLoading() {
      return this.$store.getters["billing/isLoading"];
    },
  },
  created() {
    // this.getInvoicesList();
    this.checkBillingStatus();
    // this.$store.dispatch("billing/fetchBillingInitialData").then(
    //   response => {
    //     console.log(response);
    //   },
    //   error => {
    //     console.log(error);
    //     this.$swal.fire({
    //       icon: "error",
    //       text: "Something went wrong while fetching invoices!",
    //       timer: 5000
    //     });
    //   }
    // );
    // this.$store.dispatch("billing/fetchSubscriptionData").then(
    //   response => {
    //     console.log(response);
    //   },
    //   error => {
    //     console.log(error);
    //     this.$swal.fire({
    //       icon: "error",
    //       text:
    //         "Something went wrong while fetching subscription info. Please try again later.",
    //       timer: 8000
    //     });
    //   }
    // );
    let user = JSON.parse(localStorage.getItem("userData"));
    this.userId = user.id;
  },
});
</script>

<style scoped>
.vendor-inner-custom img {
  width: 220px;
}
.vendor-inner-custom h3 {
  font-size: 23px;
  font-weight: 700;
  line-height: 1.4;
  margin: 12px 0;
  color: #000;
}
.vendor-inner-custom p {
  margin: 0 auto;
  font-size: 18px;
}
.vendor-inner-custom {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.add-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #009a73;
}
.add-icon span {
  font-size: 40px;
  font-weight: 700;
  display: inline-block;
  line-height: 60px;
}
.msg-outer {
  position: relative;
}
@media screen and (max-width: 767px) {
  .vendor-inner-custom img {
    width: 120px;
  }
  .vendor-inner-custom h3 {
    font-size: 18px;
  }
  .vendor-inner-custom p {
    font-size: 16px;
  }
}
</style>